import {ApolloProvider} from '@apollo/client';
import {useApollo} from '@lib/apolloClient';
import FeedbackProvider from '@lib/feedbackContext';
import 'tailwindcss/tailwind.css';
import React from 'react';
import Error from './_error';
import Bugsnag from '@bugsnag/js';
import Head from 'next/head';
import ActionModalProvider from '@lib/actionModalContext';

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function MyApp({Component, pageProps}) {
  const apolloClient = useApollo(pageProps);

  return (
    <>
      <Head>
        <title>Admin | COOX</title>
      </Head>
      <ErrorBoundary FallbackComponent={Error}>
        <ApolloProvider client={apolloClient}>
          <FeedbackProvider>
            <ActionModalProvider>
              <Component {...pageProps} />
            </ActionModalProvider>
          </FeedbackProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
